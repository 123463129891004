<template>
  <div class="container">
    <div class="page-title">客户列表</div>
    <div class="content">
      <div class="content-top">
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 40px;">日期:</div>
            <el-date-picker
              v-model="value2"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
            </el-date-picker>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 80px;">下单账号:</div>
            <el-input
              style="width:150px;"
              placeholder="请输入内容"
              v-model="accountName"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 80px;">平台类型:</div>
            <el-select v-model="value" placeholder="请选择" style="width:150px;">
              <el-option
                v-for="item in platformOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 120px;">是否有营销需求</div>
            <el-select v-model="value" placeholder="请选择" style="width:150px;">
              <el-option
                v-for="item in marketOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 40px;">筛选</div>
            <div>
              <el-checkbox v-model="nextBuyChecked" style="margin-right: 10px;">催回购</el-checkbox>
              <el-radio-group v-model="selectId" @change="changeHandler" size="mid">
                <el-radio-button v-for="(city,i) in selectOptions" :label="city.value" :key="city.value">{{city.label}}</el-radio-button>
              </el-radio-group>
            </div>
            <el-button style="margin:0 10px;" type="success" size="small" icon="el-icon-search" @click="query">查询</el-button>
            <el-button size="small" type="primary" icon="el-icon-delete" @click="reset">重置</el-button>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          v-loading="listLoading"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            label="创建时间"
            width="160">
            <template slot-scope="scope">
              {{scope.row.createTime | rTime}}
            </template>
          </el-table-column>
          <el-table-column
            label="平台来源"
            width="120">
            淘宝
          </el-table-column>
          <el-table-column
            prop="aliAccount"
            label="旺旺名"
            width="120">
          </el-table-column>
          <el-table-column
            prop="wechat"
            label="微信号"
            width="120">
          </el-table-column>
          <el-table-column
            prop="buyCount"
            label="消费次数"
            width="100">
          </el-table-column>
          <el-table-column
            prop="wineName"
            label="回访周期"
            width="140">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;">
                <el-input-number size="mini"  v-model="scope.row.cyclePeriod" :min="1" :max="120" placeholder="请输入（1-60）">
                </el-input-number>
                <i style="color: #67C23A;font-size: 18px;margin-left: 5px;" class="el-icon-success" @click="toSetCyclePeriod(scope.row.id, scope.row.cyclePeriod)"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="visitStatus"
            label="回访状态"
            width="220">
              <template slot-scope="scope">
                <el-select @change="((val)=>{changevisitStatus(val, scope.row.id)})" v-model="scope.row.visitStatus" placeholder="请选择" style="width: 200px;">
                  <el-option
                    v-for="item in visitStatusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
          </el-table-column>
          <el-table-column
            prop="lastOrder"
            label="最近订单"
            width="110">
          </el-table-column>
          <el-table-column
            prop="wineName"
            label="回访时间"
            width="100">
            <template slot-scope="scope">
              {{scope.row.nextVisitTime | rTime}}
            </template>
          </el-table-column>
          <el-table-column
            label="详情"
            width="100">
            <template slot-scope="scope">
              <div style="color: #409EFF;" @click="rowClick(scope.row)">查看详情 <span class="el-icon-right" ></span></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 10, 20, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        detail: {},  //详情
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value2: '',
        accountName: '',
        tableData: [],
        options: [],
        value: '',
        eatery: '',   //餐馆
        eateryOptions: [],
        areaName: '',  //店铺面积
        areaNameOptions: [],
        wineName: '',   //白酒
        wineNameOptions: [],
        marketOptions: [//营销需求
          {
            value: true,
            label: '是'
          },
          {
            value: false,
            label: '否'
          }
        ],
        platformOptions: [//平台类型
          {
            value: 1,
            label: '淘宝'
          }
        ],
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        customerCount: '',
        selectOptions: [ {
          value: 1,
          label: '待处理'
        }, {
          value: 2,
          label: '未回复'
        }, {
          value: 3,
          label: '未下单'
        }, {
          value: 4,
          label: '2未回复'
        }, {
          value: 5,
          label: '2未下单'
        }, {
          value: 6,
          label: '3未回复'
        }, {
          value: 7,
          label: '3未下单'
        }, {
          value: 8,
          label: '已回购'
        }],
        visitStatusOptions: [ {
          value: 1,
          label: '待处理'
        }, {
          value: 2,
          label: '已联系不回复'
        }, {
          value: 3,
          label: '已回复未下单'
        }, {
          value: 4,
          label: '已二次联系不回复'
        }, {
          value: 5,
          label: '已二次联系回复未下单'
        }, {
          value: 6,
          label: '已三次联系不回复'
        }, {
          value: 7,
          label: '已三次联系回复未下单'
        }, {
          value: 8,
          label: '已回购'
        }],
        selectId: '',
        nextBuyChecked: false
      };
    },
    activated () {
      this.queryData(this.currentPage,this.pageSize);
      this.$http.post('/dictionary/findChildListByParentId/1', { parentId: 1 }, { headers: {
          token: window.sessionStorage.manageToken
        }})
        .then(res => {
          console.log('res====',res)
          if (res.data.code === 'ok') {
            console.log('resdatadata====eateryOptions',res.data.data)
            this.eateryOptions = res.data.data;
            // this.$router.push({ path: "/layout" });
          } else {
            this.$message.error(res.data.data)
          }
        });
      this.$http.post('/dictionary/findChildListByParentId/2', { parentId: 2 }, { headers: {
          token: window.sessionStorage.manageToken
        }})
        .then(res => {
          console.log('res====',res)
          if (res.data.code === 'ok') {
            console.log('resdatadata====areaNameOptions',res.data.data)
            this.areaNameOptions = res.data.data;
            // this.$router.push({ path: "/layout" });
          } else {
            this.$message.error(res.data.data)
          }
        });
      this.$http.post('/dictionary/findChildListByParentId/3', { parentId: 3 }, { headers: {
          token: window.sessionStorage.manageToken
        }})
        .then(res => {
          console.log('res====',res)
          if (res.data.code === 'ok') {
            console.log('resdatadata====wineNameOptions',res.data.data)
            this.wineNameOptions = res.data.data;
          } else {
            this.$message.error(res.data.data)
          }
        });
      this.findCustomerStatus()
    },
    methods: {
      changeHandler(value) {
        this.currentPage = 1;
        this.queryData(this.currentPage,this.pageSize);
      },
      query(){
        this.currentPage = 1;
        this.queryData(this.currentPage,this.pageSize);
      },
      findCustomerStatus(){
        this.$http.post(`/customer/findCustomerStatus`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.customerCount = res.data.data && res.data.data.visitStatus;
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      toSetCyclePeriod(id,cyclePeriod){
        if(!cyclePeriod) return
        this.$http.post(`/customer/findCustomerDetail/setCyclePeriod`, {id,cyclePeriod}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.queryData(this.currentPage,this.pageSize);
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      reset(){
        this.accountName = '';
        this.selectId = '';
        this.nextBuyChecked = false;
        this.currentPage = 1;
        this.queryData(this.currentPage,this.pageSize);
      },
      queryData(currentPage,pageSize){
        this.listLoading = true;
        let data = {
          aliAccount: this.accountName,
          visitStatus: this.selectId
        }
        this.nextBuyChecked && (data.nextBuy = 1);
        console.log('data',data)
        this.$http.post(`/customer/findCustomerByPage/${currentPage}/${pageSize}`, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data.list;
              this.total = res.data.data.total;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryData(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryData(this.currentPage,this.pageSize);
      },
      rowClick(item){
        console.log(item)
        this.$router.push({ name: "OrderList",query:{accountId:item.id} });
      },
      changevisitStatus(val,id){
        console.log('val==',val,id)
        this.$http.post("/customer/updateCustomerVisitStatus", {id: id, visitStatus: val}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message.success('回访状态修改成功')
              this.queryData(this.currentPage,this.pageSize);
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      tableRowClassName({row, rowIndex}) {
        console.log('row',row)
        if (row.nextBuy === 1) {
          return 'red-row';
        }
        return '';
      }
    },
    filters: {
      rTime(data) {
        if(!data) return
        var dt = new Date(data);
        var y = dt.getFullYear();
        var m = (dt.getMonth()+1).toString().padStart(2,"0");
        var d = dt.getDate().toString().padStart(2,"0");
        var h = dt.getHours().toString().padStart(2,"0");
        var mm = dt.getMinutes().toString().padStart(2,"0");
        var s = dt.getSeconds().toString().padStart(2,"0");
        return `${y}-${m}-${d} ${h}:${mm}:${s}`;
      },
    }
  };
</script>
<style lang="less" scoped>
  /deep/ .red-row {
    color: red;
  }
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      /*justify-content: flex-start;*/
      flex-direction: column;
    }
    .first-line {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /*text-align: right;*/
      font-size: 16px;
      color: #000000;
      padding-right: 10px;
    }
    .date-box {
      display: flex;
      align-items: center;
      padding-left: 12px;
    }
    .third-line {
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  .content-bottom {
    overflow: auto;
    margin-bottom: 10px;
    flex: 1;
  }
</style>
